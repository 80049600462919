.elements-common {
    .typo-1 {
        font-family: $truss-typo-1;
    }
    .typo-2 {
        font-family: $truss-typo-2;
    }
    .typo-3 {
        font-family: $truss-typo-4;
    }

    .mb-60 {
        margin-bottom: 60px;
    }

    .typo-wrapper {
        background-color: #f3f5f7;
        border-radius: 10px;
        padding: 45px;
        &:not(:last-child) {
            margin-bottom: 50px;
        }
    }

    .typo-face {
        .heading-wrappers,
        .heading-num {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        .heading-wrappers {
            margin-bottom: 30px;
        }
    }

    .typo-inter {
        padding: 20px;
    }

    .body-paragraph {
        padding: 30px;
        border-radius: 30px;
        background-color: #202020;
        color: #fff;
    }

    .form-floating {
        > label {
            padding: 1rem;
            font-weight: 500;
        }
    }

    .nice-select {
        width: 100%;
        .list {
            width: 100%;
        }
        &.open {
            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }
        &::after {
            display: none;
        }
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 28px;
            width: 15px;
            height: 15px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E")
                no-repeat 50% 50%/14px auto;
            right: 20px;
            transition: -webkit-transform 0.2s;
            transition: transform 0.2s;
        }
    }

    .element-color {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }

    .colors-wrapper {
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        flex-wrap: wrap;
    }

    .element-block {
        text-wrap: wrap;
        .colors-box {
            width: 140px;
            height: 150px;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.05) inset 0 0 0 1px;
        }
        .color-code {
            margin-top: 5px;
            text-transform: uppercase;
            margin-bottom: 4px;
        }
    }

    .help-block {
        font-size: 14px;
        margin-top: 6px;
    }

    .list-wrapper {
        ul,
        ol {
            li {
                font-weight: 400;
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
            &.alphabetic {
                list-style-type: upper-alpha;
            }
        }
        ul {
            list-style-type: disc;
            padding-left: 2rem;
        }
    }

    @media only screen and (max-width: 767px) {
        .typo-face {
            .heading-wrappers,
            .heading-num {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .colors-wrapper {
            gap: 5px;
            grid-template-columns: repeat(1, 1fr);
        }

        .typo-show {
            margin-top: 20px;
        }

        .typo-wrapper {
            padding: 20px;
        }
        .typo-inter {
            padding: 0;
        }
        .typo-face {
            padding: 20px;
        }
    }
    @media only screen and (max-width: 350px) {
        .form-control {
            font-size: 12px !important;
        }
    }
    .form-floating,
    .form-group {
        .nice-select {
            line-height: 1.71429;
        }
    }
}

.blockquote-section {
    .bq-area {
        border-left: 1px solid #ccc;
        padding: 0.7em 25px;
        quotes: "\201C""\201D""\2018""\2019";
        &::before {
            content: open-quote;
            font-size: 6em;
            line-height: 0.1em;
            vertical-align: -0.4em;
            opacity: 0.7;
        }
        p {
            font-size: 16px;
            line-height: 28px;
        }
    }
    .cite {
        font-size: 18px;
        font-weight: 500;
    }
}

.nice-list {
    .list {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
        box-sizing: border-box;
        margin-top: 4px;
        opacity: 1;
        padding: 0;
        position: relative;
        transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
            opacity 0.15s ease-out;
        z-index: 9;
        max-height: 200px;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 20px;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #dfdfdf;
            border: 3px solid #fff;
        }
        .option {
            cursor: pointer;
            font-weight: 400;
            line-height: 40px;
            list-style: none;
            min-height: 40px;
            outline: none;
            padding-left: 18px;
            padding-right: 29px;
            text-align: left;
            transition: all 0.2s;
        }
    }
}

.loader-1 {
    width: 48px;
    height: 48px;
    border: 3px solid #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    &::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-bottom-color: #ff3d00;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.loader-2 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #fff #fff transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    &::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent #ff3d00 #ff3d00;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
        @keyframes rotationBack {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(-360deg);
            }
        }
    }
}

.loader-3 {
    width: 48px;
    height: 48px;
    border: 4px solid;
    background: rgba(255, 255, 255, 0.2);
    border-color: transparent #fff #fff transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s ease-in-out infinite;
    &::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        border: 12px solid;
        border-color: transparent #ff3d00 #ff3d00 transparent;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.loader-4 {
    width: 48px;
    height: 48px;
    border: 5px solid #ff3d00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
    &::after {
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        border: 5px solid #ff3d00;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        animation: scaleUp 1s linear infinite;
    }
    @keyframes scaleUp {
        0% {
            transform: translate(-50%, -50%) scale(0);
        }
        60%,
        100% {
            transform: translate(-50%, -50%) scale(1);
        }
    }
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        60% {
            transform: scale(1);
        }
        100% {
            transform: scale(1);
        }
        80% {
            transform: scale(1.2);
        }
    }
}

.icon-area {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    &.sm {
        height: 40px;
        width: 40px;
        font-size: 16px;
    }
}

.pagination {
    .control {
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: space-between;
        li {
            z-index: 99;
            color: #202020;
            padding: 12px;
            border-radius: 50%;
            height: 45px;
            width: 45px;
            text-align: center;
            cursor: pointer;
            box-shadow: inset 0 0 0 1px #dee2e6;
            transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            background: #fff;
        }
    }
}
