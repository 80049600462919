.module-main {
    .heading {
        font-weight: 600;
    }
    .element-block {
        padding: 32px;
        position: sticky;
        top: 0;
    }
    ul {
        line-height: 40px;
        &.gervais-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        li {
            a {
                color: #202020;
                font-size: 16px;
                font-weight: 500;
                &:hover {
                    text-decoration: underline;
                    color: #202020;
                }
            }
        }
    }
}
