// Social Icon Colors
$instagram: #e1306c;
$linkedin: #0e76a8;
$facebook: #4267b2;
$twitter: #1da1f2;
$github: #171515;
$dribbble: #ea4c89;
$whatsapp: #25d366;
$pinterest: #e60023;

// Typography
$truss-typo-1: "Poppins", sans-serif;
$truss-typo-2: "DM Sans", sans-serif;
$truss-typo-3: "Outfit", sans-serif;
$truss-typo-4: "Oswald", sans-serif;
