.page_404 {
    height: 100vh;
}

.page_404 img {
    width: 100%;
}

.page_404{
    h1{
        font-size: 8vw;
        font-weight: 700;
    }
}

.four_zero_four_bg {
    background-image: url("https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif");
    height: 400px;
    background-position: center;
    margin: 40px 0;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    border-radius: 55px;
    font-weight: 600;
}
.contant_box_404 {
    margin-top: -50px;
}
