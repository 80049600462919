.page-loader {
    position: fixed;
    z-index: 1040;
    background-color: #fff;
    height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    padding: 3em;
    flex-direction: column;
    justify-content: center;
}
.truss-homepage {
    .typo1 {
        font-family: $truss-typo-1;
    }
    .typo2 {
        font-family: $truss-typo-2;
    }
    .typo3 {
        font-family: $truss-typo-3;
    }
    .dropdown-em {
        width: 285px;
        height: 54px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 35px;
        background-color: #e6e5df;
        position: relative;
        background-color: #222;
        color: #fff;
        .dropdown-title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            width: 100%;
            height: 54px;
            font-size: 16px;
            color: #fff;
            padding: 0 20px;
            cursor: pointer;
            position: relative;
            z-index: 2;
            font-weight: 500;
            svg {
                -webkit-transition: 0.5s;
                transition: 0.5s;
                path {
                    fill: #fff;
                }
            }
        }
        .dropdown-content {
            width: 100%;
            height: 0;
            position: absolute;
            top: 46px;
            left: 0;
            z-index: 1;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 22px;
            border-radius: 0 0 26px 26px;
            background: #e6e5df;
            -webkit-transition: 1s;
            transition: 1s;
            overflow: hidden;
            -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
            background-color: #222;
            color: #fff;
            ul {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                gap: 20px;
                margin-bottom: 0;
                li {
                    color: #fff;
                    a {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        gap: 20px;
                        color: inherit;
                        font-weight: 500;
                    }
                }
            }
            img {
                width: 21px;
                height: 21px;
                object-fit: cover;
            }
        }
        &.active {
            .dropdown-title {
                svg {
                    -webkit-transform: rotate(-180deg);
                    transform: rotate(-180deg);
                }
            }
            .dropdown-content {
                -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .btn-truss {
        border: 0;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 54px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        cursor: pointer;
        position: relative;
        font-weight: 500;
        border-radius: 35px;
        background-color: #222;
        margin: auto;
    }
    .truss-main {
        position: relative;
        .overlay-hero {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .image-cover {
        .img-fluid {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .truss-experience {
        padding: 100px 0;
        background-color: #fff;
        .inner-text {
            font-size: 8vw;
            line-height: 80%;
            font-weight: 500;
        }
        .experience-left {
            p {
                padding: 0 12px;
            }
        }
        .experience-right {
            position: relative;
            .rocket {
                flex: 0 0 28%;
                margin-right: 20px;
                img {
                    animation-name: spin;
                    animation-duration: 5000ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }
                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
            .content {
                flex-grow: 1;
            }
            h4 {
                margin-bottom: 25px;
            }
        }
    }
    .truss-overview {
        margin-bottom: 100px;
        .major-text {
            margin: 100px 0;
            margin-top: 0;
            h2 {
                font-size: 5vw;
                letter-spacing: -4px;
                span {
                    background: linear-gradient(
                        to right,
                        #556fff,
                        #e05fc4,
                        #ff798e
                    );
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                }
            }
        }
        .overview-parent {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            grid-template-rows: repeat(4, 300px);
            grid-gap: 15px;
            .overview-common {
                background-color: #f7f7f9;
                border-radius: 24px;
                overflow: hidden;
                .inner-wrapper {
                    height: 100%;
                    position: relative;
                    &.blue-bg {
                        background-color: #3398ff;
                    }
                    &.gray-bg {
                        background-color: #222;
                    }
                    &.pd-30 {
                        padding: 30px;
                    }
                    .overlay-text {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 2;
                    }
                    .common-high {
                        text-transform: uppercase;
                        background: linear-gradient(
                            to right,
                            #556fff,
                            #e05fc4,
                            #ff798e
                        );
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                    }
                    .features-wrapper {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        gap: 30px 20px;
                        .img-wrapper {
                            img {
                                height: 40px;
                                width: 40px;
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
                .truss-thanks {
                    .brands-wrapper {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 10px;
                    }
                }
                &:nth-child(1) {
                    grid-area: 1/1/1/3;
                }
                &:nth-child(2) {
                    grid-area: 1/3/1/6;
                }
                &:nth-child(3) {
                    grid-area: 1/6/1/8;
                }
                &:nth-child(4) {
                    grid-area: 1/8/1/10;
                }
                &:nth-child(5) {
                    grid-area: 2/1/2/4;
                }
                &:nth-child(6) {
                    grid-area: 2/4/2/6;
                }
                &:nth-child(7) {
                    grid-area: 2/6/2/8;
                }
                &:nth-child(8) {
                    grid-area: 2/8/2/10;
                }
                &:nth-child(9) {
                    grid-area: 3/1/5/4;
                }
                &:nth-child(10) {
                    grid-area: 3/4/3/6;
                }
                &:nth-child(11) {
                    grid-area: 3/6/3/8;
                }
                &:nth-child(12) {
                    grid-area: 3/8/4/10;
                }
                &:nth-child(13) {
                    grid-area: 4/4/4/6;
                }
                &:nth-child(14) {
                    grid-area: 4/6/4/10;
                }
            }
        }
    }
    .colors-wrapper {
        display: flex;
        align-items: stretch;
        > {
            * {
                width: calc(100% / 5);
            }
        }
    }

    .truss-templates {
        background-color: #f7f7f9;
        padding: 100px 0;
        .template-card {
            margin-bottom: 50px;
            .card-wrapper {
                // clip-path: url(#lClip);
                position: relative;
                .template-img {
                    border-radius: 24px;
                    overflow: hidden;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px,
                        rgba(0, 0, 0, 0.06) 0px 0px 2px 0px;
                }
                .template-info {
                    padding: 5px 15px;
                    .template-title {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }
                    .template-tags {
                        gap: 1rem;
                        flex-wrap: wrap;
                        display: flex;
                        .tag {
                            background-color: #fff;
                            border-radius: 25px;
                            padding: 8px 14px;
                            display: inline-block;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
                .template-link {
                    .link {
                        position: absolute;
                        right: 30px;
                        bottom: 20px;
                        height: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        border-radius: 50%;
                        background-color: #fff;
                        color: inherit;
                        transition: all 0.3s ease;
                        i {
                            transform: rotate(320deg);
                        }
                        &:hover {
                            color: #3398ff;
                        }
                    }
                }
            }
        }
    }

    .truss-testimonials {
        padding: 80px 0;
        .homepage-testimonials-list {
            display: grid;
            grid-template-columns: repeat(2, 42%);
            grid-row-gap: 3rem;
            justify-content: space-between;
            align-items: start;
            .homepage-testimonial {
                padding-bottom: 2rem;
                border-bottom: 1px solid #e2e5e9;
                // &:nth-child(odd) {
                //     grid-column-start: 2;
                // }
                &:nth-child(even) {
                    position: relative;
                }
                .image-logo {
                    margin-bottom: 20px;
                }
                .homepage-testimonials-caption {
                    margin-bottom: 28px;
                    font-size: 18px;
                    color: #3f4751;
                }
                .author {
                    display: flex;
                    align-items: center;
                    .img-content {
                        margin-right: 1rem;
                    }
                    .author-info {
                        display: flex;
                        align-items: center;
                        .author-name {
                            margin-bottom: 0;
                            font-weight: 600;
                        }
                        .author-caption {
                            font-size: 13px;
                            color: #3f4751;
                        }
                    }
                }
            }
        }
    }

    .truss-download {
        padding-bottom: 80px;
        .large-paragraph {
            font-size: 20px;
            letter-spacing: -0.05vw;
        }
        .overlay-download {
            width: 270px;
            border-radius: 30px;
            overflow: hidden;
            margin: 30px auto;
        }
    }

    .truss-marquee {
        overflow: hidden;
        display: flex;
        h1 {
            font-size: 4em;
            white-space: nowrap;
            text-transform: uppercase;
            color: #fff;
        }
    }

    .inner-wrapper {
        position: relative;
        .cd-phone {
            z-index: 1;
            width: 100%;
            height: 565px;
            border-radius: 50px;
            position: relative;
            top: 80px;
            transition: all 0.5s;
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
                rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform-style: preserve-3d;
        }
        // .cd-blur{
        //     z-index: 0;
        //     background-image: url("/images/homepage/bg-blur.webp");
        //     background-position: 50%;
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     position: absolute;
        //     top: 0%;
        //     bottom: 0%;
        //     left: 0%;
        //     right: 0%;
        //     opacity: 0;
        //     transition: all 0.5s;
        // }
        &:hover {
            .cd-phone {
                transform: translate3d(0px, -15%, 0px) scale3d(0.85, 0.85, 1)
                    rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            }
            .cd-blur {
                will-change: opacity;
                opacity: 1;
            }
        }
    }

    .toast {
        width: 280px;
        background-color: #222;
        font-family: $truss-typo-2;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        .toast-body {
            padding: 20px;
            p {
                font-size: 14px;
            }
            .cookie-agreement {
                .toast-btn {
                    background-color: #fff;
                    border-radius: 10px;
                    font-size: 14px;
                    padding: 12px 10px;
                    font-weight: 600;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .truss-clocks {
        font-family: $truss-typo-3;
        .footer-clocks {
            gap: 60px;
            .clock {
                font-size: 36px;
                font-weight: 600;
            }
        }
        @keyframes blink {
            0% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        .blink {
            animation: blink 1s infinite;
        }
    }

    @media only screen and (max-width: 1280px) {
        .truss-overview {
            .overview-parent {
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: auto;
                .overview-common {
                    .colors-wrapper {
                        &.h-100 {
                            height: 100% !important;
                        }
                    }
                    .truss-typography {
                        flex-wrap: wrap;
                    }
                    &:nth-child(1) {
                        grid-area: 1/1/1/3;
                    }
                    &:nth-child(2) {
                        grid-area: 1/3/1/5;
                    }
                    &:nth-child(3) {
                        grid-area: 1/8/1/5;
                    }
                    &:nth-child(4) {
                        grid-area: 2/1/2/3;
                    }
                    &:nth-child(5) {
                        grid-area: 2/3/2/5;
                    }
                    &:nth-child(6) {
                        grid-area: 2/5/2/8;
                    }
                    &:nth-child(7) {
                        grid-area: 3/3/3/1;
                    }
                    &:nth-child(8) {
                        grid-area: 3/3/3/5;
                    }
                    &:nth-child(9) {
                        grid-area: 4/1/4/4;
                    }
                    &:nth-child(10) {
                        grid-area: 4/4/4/8;
                    }
                    &:nth-child(11) {
                        grid-area: 3/5/3/8;
                    }
                    &:nth-child(12) {
                        grid-area: 5/1/5/4;
                    }
                    &:nth-child(13) {
                        grid-area: 5/4/5/8;
                    }
                    &:nth-child(14) {
                        grid-area: 6/1/6/8;
                    }
                }
            }
        }
        .overview-common {
            .inner-wrapper {
                .cd-phone {
                    top: 30px;
                    height: 350px;
                }
                .parent {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .truss-experience {
            .experience-left {
                p {
                    margin-bottom: 25px;
                }
                .outer-text-wrapper {
                    display: flex;
                }
            }
            .experience-right {
                align-items: center;
                h4 {
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: 0;
                }
                .rocket {
                    flex: 0 0 20%;
                    margin-right: 30px;
                }
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        .truss-overview {
            .overview-parent {
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: auto;
                .overview-common {
                    .colors-wrapper {
                        &.h-100 {
                            height: 200px !important;
                        }
                    }
                    .truss-typography {
                        flex-wrap: wrap;
                    }
                    &:nth-child(1) {
                        grid-area: 1/1/1/4;
                    }
                    &:nth-child(2) {
                        grid-area: 1/4/1/8;
                    }
                    &:nth-child(3) {
                        grid-area: 2/4/2/1;
                    }
                    &:nth-child(4) {
                        grid-area: 2/4/2/8;
                    }
                    &:nth-child(5) {
                        grid-area: 3/1/3/8;
                    }
                    &:nth-child(6) {
                        grid-area: 4/4/4/8;
                    }
                    &:nth-child(7) {
                        grid-area: 5/4/5/1;
                    }
                    &:nth-child(8) {
                        grid-area: 5/4/5/8;
                    }
                    &:nth-child(9) {
                        grid-area: 6/1/6/8;
                    }
                    &:nth-child(10) {
                        grid-area: 7/1/7/4;
                    }
                    &:nth-child(11) {
                        grid-area: 4/1/4/4;
                    }
                    &:nth-child(12) {
                        grid-area: 7/4/7/8;
                    }
                    &:nth-child(13) {
                        grid-area: 8/1/8/8;
                    }
                    &:nth-child(14) {
                        grid-area: 9/1/9/8;
                    }
                }
            }
        }
        .overview-common {
            .inner-wrapper {
                .cd-phone {
                    top: 30px;
                    height: 400px;
                }
                .parent {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .dropdown-em {
            width: 235px;
        }
        .overview-common {
            .inner-wrapper {
                .cd-phone {
                    top: 40px;
                    height: 280px;
                }
            }
        }
        .truss-main {
            overflow: hidden;
            video {
                height: 432px;
                width: auto;
            }
        }
        .truss-overview {
            margin-bottom: 30px;
            .major-text {
                margin: 30px 0;
                margin-top: 0;
                h2 {
                    font-size: 7vw;
                    letter-spacing: -1px;
                }
            }
            .overview-parent {
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: auto;
                .overview-common {
                    .truss-typography {
                        flex-wrap: wrap;
                    }
                    &:nth-child(1) {
                        grid-area: 1/1/1/4;
                    }
                    &:nth-child(2) {
                        grid-area: 1/4/1/8;
                    }
                    &:nth-child(3) {
                        grid-area: 2/8/2/1;
                    }
                    &:nth-child(4) {
                        grid-area: 3/1/3/8;
                    }
                    &:nth-child(5) {
                        grid-area: 4/1/4/8;
                    }
                    &:nth-child(6) {
                        grid-area: 5/4/5/8;
                    }
                    &:nth-child(7) {
                        grid-area: 6/1/6/8;
                    }
                    &:nth-child(8) {
                        grid-area: 7/1/7/8;
                    }
                    &:nth-child(9) {
                        grid-area: 8/1/8/8;
                    }
                    &:nth-child(10) {
                        grid-area: 9/1/9/8;
                    }
                    &:nth-child(11) {
                        grid-area: 5/1/5/4;
                    }
                    &:nth-child(12) {
                        grid-area: 10/8/10/1;
                    }
                    &:nth-child(13) {
                        grid-area: 11/1/11/8;
                    }
                    &:nth-child(14) {
                        grid-area: 12/1/12/8;
                    }
                }
            }
        }
        .truss-experience {
            padding: 70px 0;
            .experience-left {
                margin-bottom: 0;
                p {
                    max-width: 100%;
                    margin-bottom: 30px;
                    margin-left: 0px;
                }
                .outer-text-wrapper {
                    display: flex;
                }
            }
            .experience-right {
                margin-top: 28px !important;
                max-width: none;
                flex-wrap: wrap;
                .rocket {
                    margin: 0 0 10px 0;
                }
                h4 {
                    margin-bottom: 10px;
                }
            }
        }
        .truss-templates {
            .template-card {
                .card-wrapper {
                    .template-img {
                        border-radius: 12px;
                    }
                    .template-info {
                        padding: 5px 10px;
                        .template-tags {
                            gap: 8px;
                            .tag {
                                padding: 6px 12px;
                                font-size: 12px;
                            }
                        }
                    }
                    .template-link {
                        .link {
                            right: 10px;
                            bottom: 40px;
                            height: 40px;
                            width: 40px;
                        }
                    }
                }
            }
        }
        .truss-testimonials {
            .homepage-testimonials-list {
                display: block;
                .homepage-testimonial {
                    border-bottom: 1px solid #e2e5e9;
                    margin-bottom: 2rem;
                    &:nth-child(even) {
                        position: relative;
                        top: 0;
                    }
                    .image-logo {
                        margin-bottom: 20px;
                    }
                    .homepage-testimonials-caption {
                        margin-bottom: 20px;
                    }
                    .author {
                        display: flex;
                        align-items: center;
                        .img-content {
                            margin-right: 1rem;
                        }
                        .author-info {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            .author-name {
                                margin-bottom: 0;
                                font-weight: 600;
                                font-size: 14px;
                            }
                            .author-caption {
                                font-size: 13px;
                                color: #3f4751;
                            }
                        }
                    }
                }
            }
        }
        .truss-download {
            .large-paragraph {
                font-size: 18px;
            }
        }
        .truss-clocks {
            .footer-clocks {
                gap: auto;
                &.justify-content-center {
                    justify-content: space-evenly !important;
                }
                .clock {
                    font-size: 24px;
                    font-weight: 600;
                }
            }
        }
    }
    @media only screen and (max-width: 430px) {
        .truss-overview {
            .overview-parent {
                .overview-common {
                    .inner-wrapper {
                        .cd-phone {
                            top: 10px;
                        }
                        &.blue-bg {
                            max-height: 300px;
                        }
                        .features-wrapper {
                            grid-template-columns: repeat(2, 1fr);
                            gap: 15px;
                        }
                    }
                    &:nth-child(1) {
                        grid-area: 1/1/1/8 !important;
                    }
                    &:nth-child(2) {
                        grid-area: 2/1/2/8 !important;
                    }
                    &:nth-child(3) {
                        grid-area: 3/8/3/1;
                    }
                    &:nth-child(4) {
                        grid-area: 4/1/4/8;
                    }
                    &:nth-child(5) {
                        grid-area: 5/1/5/8;
                    }
                    &:nth-child(6) {
                        grid-area: 6/4/6/8;
                    }
                    &:nth-child(7) {
                        grid-area: 7/1/7/8;
                    }
                    &:nth-child(8) {
                        grid-area: 8/1/8/8;
                    }
                    &:nth-child(9) {
                        grid-area: 9/1/9/8;
                    }
                    &:nth-child(10) {
                        grid-area: 10/1/10/8;
                    }
                    &:nth-child(11) {
                        grid-area: 6/1/7/4;
                    }
                    &:nth-child(12) {
                        grid-area: 11/8/11/1;
                    }
                    &:nth-child(13) {
                        grid-area: 12/1/20/8;
                    }
                    &:nth-child(14) {
                        grid-area: 20/1/20/8;
                    }
                }
            }
        }
    }
}
