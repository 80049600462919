// Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&display=swap");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// jQuery-ui
// @import '~jquery-ui/themes/base/all.css';

// Font Awesome Icons 6.4.2
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

// Bootstrap Icons 1.10
@import "bootstrap-icons/font/bootstrap-icons.css";

// Plugin's Styles
@import "tiny-slider/src/tiny-slider.scss";
@import "nouislider/dist/nouislider.css";
@import "daterangepicker/daterangepicker.css";
@import "nice-select";

// Template Styles
@import "custom";
@import "typography";
@import "elements-common";
@import "truss";
@import "module-links";
@import "404";
// @import "ecom-2";
// @import "gervais-rentals";
