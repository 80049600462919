body {
    position: relative;
    background: $white;
    overflow-x: hidden;
    font-family: $truss-typo-1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
p {
    font-size: 16px;
}

a {
    text-decoration: none;
}
ul {
    list-style: none;
    padding-left: 0;
}

.bg-body-tertiary {
    background-color: #fff !important;
}

.scrollToTopBtn {
    background-color: #202020;
    border: none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    line-height: 48px;
    width: 48px;
    /* place it at the bottom right corner */
    position: fixed;
    bottom: 30px;
    right: 30px;
    /* keep it on top of everything else */
    z-index: 100;
    /* hide with opacity */
    opacity: 0;
    /* also add a translate effect */
    transform: translateY(100px);
    /* and a transition */
    transition: all 0.5s ease;
    &.showBtn {
        opacity: 1;
        transform: translateY(0);
    }
}

.accordion {
    .accordion-item {
        border: 0;
        margin-bottom: 8px;
        .accordion-header {
            .accordion-button {
                box-shadow: none;
                background: transparent;
                padding-left: 2rem;
                font-weight: 600;
            }
        }
        .accordion-body {
            padding-left: 2rem;
            font-weight: 400;
            padding-top: 0;
        }
    }
}

.was-validated {
    .form-control {
        &:valid,
        &:invalid {
            background-image: none;
            &:focus {
                box-shadow: none;
            }
        }
        &:invalid {
            &:hover {
                border-color: #dc3545;
            }
        }
        &:valid {
            &:hover {
                border-color: #198754;
            }
        }
    }
}

.invalid-feedback {
    font-weight: 500;
    color: #dc3545 !important;
}

.inset-0 {
    inset: 0;
    z-index: 1;
}

.privacy-common {
    .privacy-header {
        padding-top: 60px;
        text-align: center;
        padding-bottom: 10px;
        h2 {
            font-size: 65px;
        }
    }
    .privacy-heading {
        h2 {
            margin-top: 2em;
            margin-bottom: 0.5em;
            font-weight: 600;
        }
    }
    .privacy-content {
        p {
            strong {
                font-weight: 600;
            }
        }
        a {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 3px;
        }
        .sub-heading {
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 767px) {
    .privacy-header {
        h2 {
            font-size: 40px !important;
        }
    }
}
