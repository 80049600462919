// Font Size
.text-xxs {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.2px;
}

.text-xs {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
}
.text-sm {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
}
.text-base {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
}
.text-md {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
}

.text-lg {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
}

.text-xl {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
}

.text-xxl {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
}

.font-italic {
    font-style: italic;
}

.font-light {
    font-weight: 200 !important;
}

.font-normal {
    font-weight: 300 !important;
}

.font-regular {
    font-weight: 400 !important;
}

.font-medium {
    font-weight: 500 !important;
}

.font-semibold {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 700 !important;
}
.text-20 {
    font-size: 20px;
    line-height: 22px;
}
// Font Size End
.space {
    margin-bottom: 30px;
}
